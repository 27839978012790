.App {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #666666;
  align-items: center;
}


.title {
  color: white;
  text-align: center;
}

.function{
  display: flex;
  width: 800px;
  flex-direction: column;
  align-self: center;
}

.sequencer {
  height: 100%;
  display: flex;
  text-align: center;
  align-self: center;
  margin: 30px;
}
.key-column {
  display: flex;
  flex-direction: column;
}
.row-key {
  height: 40px;
  width: 40px;
  color: white;
  margin: 4px;
  line-height: 40px;
}

.row-key-onMode{
  height: 40px;
  width: 40px;
  color: white;
  margin: 4px;
  line-height: 40px;
}

.note-wrapper {
  height: 100%;
  display: flex;
}

.note-column {
  display: flex;
  flex-direction: column;
}
.note-column--active {
  background: #228822;
}
.note {
  height: 40px;
  width: 40px;
  color: #858585;
  background: #858585;
  border: 2px solid #ccc;
  border-radius: 2px;
  margin: 4px;
  cursor: pointer;
}
.note--active {
  color: white;
  background: #ef8a8c;
}
.note:active {
  background: lightgrey;
}

.function-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.action-button{
  padding: 0.5rem 1.5rem;
  border: 2px solid #ef8a8c;
  border-radius: 4px;
  cursor: pointer;
}

.function-sliders{
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.slider-wraper{
  display: flex;
  flex-direction: row;
  color: white;
  line-height: 20px;
  margin-right: 20px;
}

.slider-wraper:last-of-type{
  margin-right: 0;
}

.slider {
  -webkit-appearance: none;
  width: 250px;
  height: 20px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-right: 10px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.slider-label{
  align-self: center;
}
.selector{  
  display: flex;
  flex-direction: row;
  margin-right: 18px;
}

.selector-label{
  color:white;
  font-size: 20px;
  align-self: center;
  margin-right: 12px;
}

.selector-input {
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  border: 2px solid #ef8a8c;
  border-radius: 4px;
  cursor: pointer;
}